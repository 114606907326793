"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useState, useEffect } from "react";
import { Blurhash } from "react-blurhash";
import axios from "axios"; // Axios for API calls
import { Pagination, Navigation } from "swiper/modules";

// Main Component for Upcoming Events
export default function UpcomingEventsOnHome() {
  return (
    <>
      <h1 className="text-lg sm:text-left text-center sm:text-3xl text-emerald-900 font-bold sm:font-semibold antialiased font-Poppins mb-1 mt-10 sm:ml-6">
        Upcoming Events
      </h1>
      <h2 className="text-sm font-Poppins sm:text-left text-center sm:text-xl text-emerald-900 font-normal antialiased mb-5 sm:ml-6">
        Stay Updated to know what's next.
      </h2>

      <div className="px-4 mb-11">
        <UpcomingEventsOnHomeSwiper />
      </div>
    </>
  );
}

// Swiper Component with API Fetch
const UpcomingEventsOnHomeSwiper = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [imageLoaded, setImageLoaded] = useState({});
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Function to handle image load
  const handleImageLoad = (index) => {
    setImageLoaded((prev) => ({ ...prev, [index]: true }));
  };
  // Fetch the events from the API
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://seedafriqueswegp-backend.onrender.com/api/events"
        ); // Replace with your actual API endpoint

        console.log("API Response:", response.data); // Log the API response for debugging

        // Get the current date (only the date part, without time)
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to start of the day (00:00:00)

        // Filter and sort the events by date
        const filteredAndSortedEvents = response.data
          .filter((event) => {
            const eventDate = new Date(event.eventDate);
            console.log("Event Date:", eventDate); // Log each event date for debugging
            return eventDate >= today;
          })
          .sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate));

        console.log("Filtered and Sorted Events:", filteredAndSortedEvents); // Log the filtered and sorted events

        setUpcomingEvents(filteredAndSortedEvents);
        setLoading(false);
      } catch (err) {
        console.error("API Error:", err); // Log the error if the API call fails
        setError("Failed to load events. Please try again later.");
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-28 sm:h-36">
        {/* Loading Spinner */}
        <svg
          className="animate-spin h-10 w-10 text-green-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <p className="text- sm:text-xl text-gray-600 ml-3">Loading events...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-64">
        <p className="text-red-500 text-xl">
          {error || "There was an issue fetching events."}
        </p>
      </div>
    );
  }

  if (!upcomingEvents.length) {
    return (
      <div className="flex justify-center items-center h-28 sm:h-36 flex-col">
        <svg
          className="h-16 w-16 text-aboutbg mb-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 8c-1.1046 0-2 .8954-2 2s.8954 2 2 2 2-.8954 2-2-.8954-2-2-2zm0 8a5.979 5.979 0 01-4.244-1.757A5.979 5.979 0 016 12H4a7.979 7.979 0 002.343 5.657A7.979 7.979 0 0012 20a7.979 7.979 0 005.657-2.343A7.979 7.979 0 0020 12h-2a5.979 5.979 0 01-1.757 4.243A5.979 5.979 0 0112 16z"
          />
        </svg>
        <p className="text-base sm:text-xl text-gray-600 text-center bg-red-300 px-3 py-1 sm:px-5 sm:py-3 rounded-xl">
          No upcoming events available at the moment.
        </p>
      </div>
    );
  }

  return (
    <Swiper
      spaceBetween={20}
      slidesPerView={1}
      navigation={{ clickable: true }}
      breakpoints={{
        550: { slidesPerView: 1 },
        580: { slidesPerView: 2 },
        860: { slidesPerView: 3 },
        1024: { slidesPerView: 4 },
      }}
      pagination={{ dynamicBullets: true }}
      modules={[Pagination, Navigation]}
    >
      {upcomingEvents.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="rounded-2xl ml-1 mb-1 mr-1">
            {/* Blurhash loading (used before the image loads) */}
            <div className="w-full md:h-48 h-60 object-cover mb-4 rounded-t-2xl relative">
              {!imageLoaded[index] && (
                <div className="w-full md:h-48 inset-0 rounded-t-2xl overflow-hidden">
                  <Blurhash
                    hash={
                      item.blurHash ||
                      "VT5ap~fQeogff*d8e:e:b]fQcqf*e.fkf7gfg2f+e.eo"
                    } // Default Blurhash
                    width={"100%"}
                    height={240} // Adjust height to match the height of the actual image
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                  />
                </div>
              )}
              <div>
                <img
                  src={
                    item.imageUrl ||
                    "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSeedAfTogether2resized.png?alt=media&token=ed49daec-587a-42b0-ab5e-4af04a24462"
                  } // Fallback image
                  alt={item.title || "Event Image"}
                  className={`w-full md:h-48 h-60 object-cover mb-4 rounded-t-2xl ${
                    imageLoaded[index] ? "" : "hidden"
                  }`}
                  onLoad={() => handleImageLoad(index)}
                />
              </div>

              {/* Date section */}
              <div className="flex justify-end absolute inset-0">
                <div className="rounded-xl border-4 h-20 border-green-950 p-2 mr-1 mt-1 bg-paleGreen flex flex-col justify-between">
                  <center>
                    <p className="text-xs text-green-950 font-Poppins">
                      {item.day || "Unknown Day"}
                    </p>
                  </center>
                  <center>
                    <p className="text-xs text-green-950 font-bold font-Poppins">
                      {item.datenumber || "--"}
                    </p>
                  </center>
                  <center>
                    <p className="text-xs text-green-950 font-Poppins">
                      {item.monthandyear || "Unknown Date"}
                    </p>
                  </center>
                </div>
              </div>
            </div>

            {/* Main Content */}
            <div className="flex flex-col justify-between space-y-px">
              <div>
                {/* Event Category */}
                <h2 className="rounded-md w-24 text-sm bg-green-800 text-lime-50 text-center mt-2 py-1 font-Poppins">
                  {item.category || "Uncategorized"}
                </h2>

                {/* Event Title */}
                <h1 className="text-lg font-semibold text-green-950 mt-2 font-Poppins">
                  {item.title || "Untitled Event"}
                </h1>
              </div>

              {/* Event Description */}
              <div>
                <p className="text-emerald-900 mb-2 text-sm font-Poppins">
                  {item.description || "No description available."}
                </p>
              </div>

              {/* Event Venue */}
              <div className="">
                <a className="text-emerald-900 mb-2 underline" href="#">
                  {item.venue || "No venue specified"}
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
