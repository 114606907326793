"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useState } from "react";
import { Blurhash } from "react-blurhash";

import { Pagination } from "swiper/modules";

export default function BlogOnHome() {
  return (
    <>
      {/* SEEDAfrique blog title located at the top. */}
      <h1 className="sm:text-left text-center text-lg sm:text-3xl text-emerald-900 font-bold sm:font-semibold antialiased font-sans mb-5 sm:ml-6">
        {" "}
        SEEDAfrique BLOG{" "}
      </h1>

      {/* The actual body */}
      <div className="px-4">
        <BlogSwiper></BlogSwiper>
      </div>

      {/* View all button */}
      <div className="flex justify-end m-6">
        <button className="bg-transparent text-emerald-900 font-bold py-2 px-4 rounded-xl shadow-md border border-emerald-900">
          {" "}
          View All{" "}
        </button>
      </div>
    </>
  );
}

const BlogSwiper = () => {
  // This is the data array with BlurHash strings
  const data = [
    {
      imageUrl: "/seedafriqueimgs/PHOTO-2024-01-09-19-58-00.jpeg",
      blurHash: "L5H2EC=PM+yV0g-mq.wG9c010J}I",
      alt: "An image of Uncle billy",
      category: "Technology",
      title: "Meet My Team",
      author: "Uncle Billy",
      date: "11th Jan 2023",
      readTime: "20 mins",
      description:
        "I have the most active vibrant young men in my team i am so proud and very glad to have them. What would i do without them. Join the team now.",
    },
    {
      imageUrl: "/blogimages/takyiskywitharobot.jpg",
      blurHash: "L8DttR^*00008D%M?ZMx_3?^IVbH",
      alt: "An image of Takyisky holding a bot.",
      category: "Technology",
      title: "Boy Buys Tesla",
      author: "Nathaniel",
      date: "13th Jan 2022",
      readTime: "3 mins",
      description:
        "At the age of 35 years takyisky buys the whole Tesla brand from Elon Musk.",
    },
    {
      imageUrl: "/seedafriqueimgs/PHOTO-2023-12-17-13-35-12.jpeg",
      blurHash: "L35F5|_3*0U^?]s:WB%#?^t7bbIA",
      alt: "An image to show how we connect",
      category: "Sports",
      title: "We help Connect",
      author: "Takyisky",
      date: "13th Jan 2022",
      readTime: "3 mins",
      description:
        "Socialization is key, links are important. We are aimed at creating more links and to equip you with more opportunities.",
    },
    {
      imageUrl: "/blogimages/Allaficangames2.jpg",
      blurHash: "L8I|9z?b00D%IqxZtSog0fn#={t7",
      alt: "An image of Uncle billy",
      category: "Sports",
      title: "The All African Games",
      author: "Takyisky",
      date: "13th Jan 2022",
      readTime: "3 mins",
      description:
        "The 13th All African Games currently ongoing has brought together Africa's top athletes in a diverse array of sports, fostering unity and sportsmanship in a vibrant setting.",
    },
    // In the future more blog info would be added here
  ];

  //Swiper Codes
  return (
    <Swiper
      spaceBetween={20}
      slidesPerView={1}
      breakpoints={{
        550: { slidesPerView: 1 },
        580: { slidesPerView: 2 },
        860: { slidesPerView: 3 },
        1024: { slidesPerView: 4 },
      }}
      pagination={{
        dynamicBullets: true,
      }}
      modules={[Pagination]}
    >
      {data.map((item, index) => (
        <SwiperSlide key={index}>
          <BlogCard item={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const BlogCard = ({ item }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div
      className="rounded-2xl shadow-md mb-1 ml-1 mr-1 border border-emerald-900 flex flex-col justify-between"
      style={{ height: "530px" }}
    >
      <div>
        {/* Blog Image */}
        {!imageLoaded && (
          <div className=" inset-0 rounded-t-2xl overflow-hidden">
            <Blurhash
              hash={item.blurHash}
              width={"100%"}
              height={250} // Adjust height to match the height of the actual image
              resolutionX={32}
              resolutionY={32}
              punch={1}
            />
          </div>
        )}
        <img
          src={item.imageUrl}
          alt={item.alt}
          className={`w-full md:h-48 h-60 object-cover mb-4 rounded-t-2xl ${
            imageLoaded ? "" : "hidden"
          }`}
          onLoad={() => setImageLoaded(true)}
        />
        <h2 className="text-sm font-bold px-5 text-emerald-900">
          {item.category}
        </h2>
      </div>

      {/* Blog title and its body */}
      <div>
        <h1 className="text-lg font-semibold px-5 text-green-950">
          {item.title}
        </h1>
        <p className="text-emerald-900 mb-2 px-5 text-sm">{item.description}</p>
        <a className="text-emerald-900 mb-2 px-5">Join Us</a>
      </div>

      {/* The Blogger Section */}
      <div className="p-6 flex items-center">
        <div className="flex items-center justify-center mr-3">
          {/* Profile Picture */}
          <img
            src={item.imageUrl}
            alt="Image of Blog Author"
            className="md:w-14 md:h-11 w-12 h-12 object-cover rounded-full"
          ></img>
        </div>
        <div>
          <div className="items-center">
            <div className="text-green-950 mr-2 font-bold">{item.author}</div>
            <span className="text-emerald-900 text-sm">
              {item.date} . {item.readTime} read
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
