"use client";
import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { Blurhash } from "react-blurhash";

// Main
export default function JoinUs() {
  return (
    <>
      <JoinUsMobileVer />
      <JoinUSDesktopVer />
    </>
  );
}

// COMPONENTS

// Join us Component for mobile devices
const JoinUsMobileVer = () => {
  // State variables to hold form data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [whyJoinSeedafrique, setwhyJoinSeedafrique] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  // handle Submit handles the submission of the form
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    // Prepare the template parameters for EmailJS
    const templateParams = {
      from_name: name,
      email: email,
      contact: contact,
      message: whyJoinSeedafrique,
    };

    // Send the email using EmailJS
    emailjs
      .send(
        "service_wqqk7vs", // replace with your EmailJS service ID
        "template_f9hpi1i", // replace with your EmailJS template ID
        templateParams,
        "e2H59RJpj1qQJm8Kq" // replace with your EmailJS user ID
      )
      .then(
        (response) => {
          // Refresh the page
          window.location.reload();
          console.log("SUCCESS!", response.status, response.text);
          alert("Email sent successfully!");
        },
        (err) => {
          console.error("FAILED...", err);
          alert("Failed to send the email.");
        }
      );
  };

  useEffect(() => {
    const img = new Image();
    img.src =
      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSeedAfTogether2resized.png?alt=media&token=ed49daec-587a-42b0-ab5e-4af04a24462e";

    // When the image is fully loaded, set imageLoaded to true
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []); // Empty dependency array ensures this runs only once

  function showAlert() {
    alert(
      " We are currently reviewing the Terms and Conditions. They will be shared with you via email shortly. Thank You!"
    );
  }

  return (
    <>
      <form action="" method="" onSubmit={handleSubmit}>
        {/* Mobile Devices and tablets only */}
        {/* the div with the image background  */}
        <div className="lg:hidden visible">
          <div className="mx-4 rounded-xl relative ">
            {imageLoaded ? (
              <img
                className="rounded-xl object-cover h-[600px] w-full  "
                src="https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSeedAfTogether2resized.png?alt=media&token=ed49daec-587a-42b0-ab5e-4af04a24462e"
              />
            ) : (
              <div className="object-cover overflow-hidden rounded-xl h-[600px] w-full">
                <Blurhash
                  hash="[18g.@=9$u_24=OINO9G95%EDg9bRgxA_0x]?]sjx[o#$^RkS*oyZ~j_RQM{IDbFaMWrROWUt6tP"
                  width={"100%"}
                  height={"100%"} // Adjust height to match the height of the actual image
                  resolutionX={32}
                  resolutionY={32}
                  punch={1}
                />
              </div>
            )}

            {/* top  */}
            {/* I Added the id so that i can refrence it well. When any join us button is clicked anywhere it would bring you to that section */}
            <div className="absolute inset-0">
              <div id="target-section">
                <center>
                  <h1 className="text-3xl text-white font-semibold antialiased font-sans pt-7 mb-6 mt-5 text-center">
                    Join Us
                  </h1>
                </center>
              </div>
              {/* body  */}
              <div className="flex justify-center">
                <div>
                  <p className="text-white">Name</p>
                  <input
                    type="text"
                    name="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ border: "1px solid" }}
                    className="text-left w-64 sm:w-72 px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                    required
                  />
                  <p className="text-white mt-2">Email</p>
                  <input
                    type="email"
                    name="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ border: "1px solid" }}
                    className="text-left w-64 sm:w-72 px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                    required
                  />
                  <p className="text-white mt-2">Contact</p>
                  <input
                    type="tel"
                    name="Contact"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    style={{ border: "1px solid" }}
                    className="text-left w-64 sm:w-72 px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                    required
                  />
                  <p className="text-white mt-2 w-64 sm:w-72 ">
                    How do you hope to contribute or benefit from SEEDAfrique?
                  </p>
                  {/* what you do mutiline input */}
                  <textarea
                    id="multiline-input"
                    rows={4}
                    className="text-left w-64 sm:w-72 h-28 px-3 py-2 border text-white object-cover bg-opacity-20 bg-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                    style={{
                      backgroundImage:
                        "url('/logo/SeedAfrique Org Logo grey 2.png')",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    value={whyJoinSeedafrique}
                    onChange={(e) => setwhyJoinSeedafrique(e.target.value)}
                    required
                  />
                </div>
              </div>
              {/* End section of the join us (accepting the terms and conditions) */}
              <div className="mt-7 pb-7">
                <div className="flex items-center justify-center">
                  <div className="flex items-center">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="checkbox"
                        className="mr-2 h-6 w-6 rounded border-gray-300  focus:ring-indigo-500 "
                        checked={acceptedTerms}
                        onChange={(e) => setAcceptedTerms(e.target.checked)}
                        required
                      />
                    </div>
                    <label
                      htmlFor="checkbox"
                      className="mr-4 text-white text-base font-semibold"
                    >
                      I accept the{" "}
                      <button onClick={showAlert} className="underline">
                        Terms
                      </button>
                    </label>
                  </div>
                  {/* Move the button inside the same flex container */}
                  <button
                    type="submit"
                    className="bg-buttonColor text-xs p-1 px-3 rounded-full hover:bg-green-600"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

// Join Us Component for Desktop devices
const JoinUSDesktopVer = () => {
  // State variables to hold form data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [whyJoinSeedafrique, setwhyJoinSeedafrique] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    // Prepare the template parameters for EmailJS
    const templateParams = {
      from_name: name,
      email: email,
      contact: contact,
      message: whyJoinSeedafrique,
    };

    // Send the email using EmailJS
    emailjs
      .send(
        "service_wqqk7vs", // replace with your EmailJS service ID
        "template_f9hpi1i", // replace with your EmailJS template ID
        templateParams,
        "e2H59RJpj1qQJm8Kq" // replace with your EmailJS user ID
      )
      .then(
        (response) => {
          // Refresh the page
          window.location.reload();
          console.log("SUCCESS!", response.status, response.text);
          alert("Email sent successfully!");
        },
        (err) => {
          console.error("FAILED...", err);
          alert("Failed to send the email.");
        }
      );
  };

  useEffect(() => {
    const img = new Image();
    img.src =
      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSeedAfTogether2resized.png?alt=media&token=ed49daec-587a-42b0-ab5e-4af04a24462e";

    // When the image is fully loaded, set imageLoaded to true
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []); // Empty dependency array ensures this runs only once

  function showAlert() {
    alert(
      " We are currently reviewing the Terms and Conditions. They will be shared with you via email shortly. Thank You!"
    );
  }

  return (
    // On submit is there so that when youclick the submit it handles it
    <form onSubmit={handleSubmit}>
      {/* for large screen devices */}
      <div className="hidden lg:block">
        <div className="mx-4 rounded-xl relative">
          {imageLoaded ? (
            <img
              className="rounded-xl object-cover h-[400px] w-full"
              src="https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSeedAfTogether2resized.png?alt=media&token=ed49daec-587a-42b0-ab5e-4af04a24462e"
            />
          ) : (
            <div className="rounded-xl blurhash styling overflow-hidden h-[400px] w-full">
              <Blurhash
                hash="[18g.@=9$u_24=OINO9G95%EDg9bRgxA_0x]?]sjx[o#$^RkS*oyZ~j_RQM{IDbFaMWrROWUt6tP"
                width={"100%"}
                height={"100%"} // Adjust height to match the height of the actual image
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            </div>
          )}
          <div className="absolute inset-0">
            {/* Head */}
            {/* I Added the id so that i can reference it well on mobile devices. When any join us button is clicked anywhere it would bring you to that section */}
            <div>
              <h1 className="text-3xl text-white font-semibold antialiased font-sans pt-7 mb-6 mt-5 text-center">
                Join Us
              </h1>
            </div>

            {/* Body  */}
            <div id="whatdoyoudoformid">
              <div className="flex items-center justify-between px-28">
                <div>
                  <p className="text-white">Name</p>
                  <input
                    type="text"
                    name="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ border: "1px solid white", color: "white" }}
                    className="text-left w-80 px-3 py-2 bg-transparent border border-white text-white rounded-xl"
                    required
                  />

                  <p className="text-white mt-1 ">Email</p>
                  <input
                    type="email"
                    name="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ border: "1px solid white", color: "white" }}
                    className="text-left w-80 px-3 py-2 bg-transparent border border-white text-white rounded-xl"
                    required
                  />

                  <p className="text-white mt-1 ">Contact</p>
                  <input
                    type="tel"
                    name="Contact"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    style={{ border: "1px solid white", color: "white" }}
                    className="text-left w-80 px-3 py-2 bg-transparent border border-white text-white rounded-xl"
                    required
                  />
                </div>

                {/* horizontal line  */}
                <div className="firstVerticalLine w-0.5 h-32 bg-white" />

                <div>
                  <p className="text-white w-80 py-2">
                    How do you hope to contribute or benefit from SEEDAfrique?
                  </p>
                  <textarea
                    id="multiline-input"
                    rows={4}
                    value={whyJoinSeedafrique}
                    onChange={(e) => setwhyJoinSeedafrique(e.target.value)}
                    className=" w-80 h-36 px-3 py-2 border text-white object-cover bg-opacity-20 bg-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                    style={{
                      backgroundImage:
                        "url('/logo/SeedAfrique Org Logo grey 2.png')",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    required
                  />
                </div>
              </div>
            </div>

            {/* End section of the join us (accepting the terms and conditions) */}
            <div className="mt-7 pb-7">
              <div className="flex items-center justify-center">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="checkbox"
                    className="mr-2 h-6 w-6 rounded border-gray-300  focus:ring-indigo-500"
                    checked={acceptedTerms}
                    onChange={(e) => setAcceptedTerms(e.target.checked)}
                    required
                  />
                  <label
                    htmlFor="checkbox"
                    className="mr-4 text-white text-base font-semibold"
                  >
                    I accept the{" "}
                    <button onClick={showAlert} className="underline">
                      Terms
                    </button>
                  </label>
                </div>

                <button
                  type="submit"
                  className="bg-buttonColor text-xs p-1 px-3 rounded-full hover:bg-green-600"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
