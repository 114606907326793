// About SEEDAfrique , Core Values , the Mission and  Vission

import { useState, useEffect } from "react";
import { Blurhash } from "react-blurhash";
import { motion, useScroll, useTransform } from "framer-motion";

//  Main
const SeedAfriqueOrganization = () => {
  return (
    <div>
      <SeedAfOrgLayout />
    </div>
  );
};

export default SeedAfriqueOrganization;

// FUNCTION COMPONENTS
const SeedAfOrgLayout = () => {
  return (
    <>
      {/* Seed Afrique Organization text with some text on the left and the mission on the right side  */}
      <SeedAfriqueAbout />

      {/* Core values and goals for Big Screens only */}
      <CVAGBigScreens />

      {/* Core values and goals for Small Screens only */}
      <CVAGSmallScreens />
    </>
  );
};

// About SeedAfrique - All Screens
function SeedAfriqueAbout() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src =
      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2Fblurry-mission-and-vission.png?alt=media&token=0c5e6050-c7d9-4d4c-99ed-a0ee006be3e0";

    // When the image is fully loaded, set imageLoaded to true
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div className="flex flex-col md:flex-row justify-between items-center p-4 sm:p-8 mt-3 sm:mt-10">
      {/* left side  */}
      <motion.div className="md:w-1/2 md:pr-8 mb-8 md:mb-0 text-center md:text-left">
        <h1 className="font-poppins text-lg font-bold sm:text-4xl sm:font-extrabold mb-2 sm:mb-6 text-teal-950">
          SEEDAfrique Organization
        </h1>
        <p className="font-Poppins text-sm sm:text-lg text-darkGreen leading-5 sm:leading-8">
          The Organization develops and harnesses the young minds of the growing
          Youth in Ghana and Africa. Our aim is to stimulate the interest of the
          Youth in Engineering and other STEM-related fields by connecting them
          to industries of their specialization to further build and hone their
          skills and talents. Membership is open to young people from the Junior
          through Senior High School level (K-12) to the graduate/early career
          level
        </p>
      </motion.div>

      {/* right side  */}
      {/* The image with Takyisky on it */}
      <motion.div className="md:w-1/2 w-full rounded-xl text-paleGreen p-2 sm:p-5 relative">
        {imageLoaded ? (
          <img
            src="https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2Fblurry-mission-and-vission.png?alt=media&token=0c5e6050-c7d9-4d4c-99ed-a0ee006be3e0"
            className="object-cover rounded-xl w-full h-[400px] md:h-[700px] lg:h-[600px] "
          />
        ) : (
          <div className="object-cover overflow-hidden rounded-xl w-full h-[400px] md:h-[700px] lg:h-[600px]">
            <Blurhash
              hash="]56+hI8_*J.8MxPpx^MdVsn$tmShxYaxbbH=niROWVkqNGn$bcbvs.n$bbtRe.V?R5t8R+V@WBo#Rjs:kCoJM|o0aKkBbc"
              width={"100%"}
              height={"100%"} // Adjust height to match the height of the actual image
              resolutionX={32}
              resolutionY={32}
              punch={1}
            />
          </div>
        )}

        <div className="absolute inset-0 p-4 sm:p-8 space-y-4 flex flex-col justify-center items-center ">
          <p className="font-Poppins text-sm sm:text-lg sm:my-2">
            {" "}
            <span className="font-bold text-white"> Our Mission</span> is to
            lead the African youth through STEM Education for viable
            opportunities.
          </p>
          <p className="font-Poppins text-sm sm:text-lg my-2">
            {" "}
            <span className="font-bold text-white ">
              {" "}
              Strategic Engineering Experience DriveAfrique (SEEDAfrique){" "}
            </span>{" "}
            is an organisation that connects individuals with STEM Interest for
            growth.
          </p>
          <p className="font-Poppins text-sm sm:text-lg my-2">
            {" "}
            <span className="font-bold text-white "> Our Vision </span> is to
            create a pool of dynamic experts for social and economic growth.{" "}
          </p>
          <p className="font-Poppins text-sm sm:text-lg my-2">
            {" "}
            Our sole purpose is to engage students in{" "}
            <span className="font-bold text-white"> STEM </span> activities to
            develop their skills in problem-solving, creativity, and critical
            analysis to achieve their potential for transformation.{" "}
          </p>
        </div>
      </motion.div>
    </div>
  );
}

// Core values and goals for Big Screens only (The Function)
function CVAGBigScreens() {
  const [imageLoaded, setImageLoaded] = useState(false);

  // So imediately the page refreshes the use effect actiohn will ran.
  useEffect(() => {
    const img = new Image();
    img.src =
      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2Fblurry-corevalues-and-goals.png?alt=media&token=b21d3651-a137-44df-87bb-54aa715314ba";

    // When the image is fully loaded, set imageLoaded to true
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div className=" md:visible hidden md:flex md:justify-center relative">
      <div className="md:w-full rounded-xl bg-center text-paleGreen p-8 flex">
        {imageLoaded ? (
          <img
            className="object-cover rounded-xl w-full md:h-[400px] lg:h-[500px]"
            src="https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2Fblurry-corevalues-and-goals.png?alt=media&token=b21d3651-a137-44df-87bb-54aa715314ba"
          />
        ) : (
          <div className="object-cover overflow-hidden rounded-xl w-full md:h-[400px] lg:h-[500px]">
            <Blurhash
              hash="]C8|t-bHRjxuoe~WWCWBxujs.7ofWBoLjZ%Lt6ofWBR*t7ofoeRjayoLbHofRjafj[oLayaya|ofj[WBayayazWBWVoLj[" // String for the blurry core values and goals.
              width={"100%"}
              height={"100%"} // Adjust height to match the height of the actual image
              resolutionX={32}
              resolutionY={32}
              punch={1}
            />
          </div>
        )}

        {/* The absolute is like telling the div to foget that there are other siblings it should just sync with its relative parent */}
        <div className=" absolute inset-0 flex p-10">
          {/* Core values */}
          <div className="w-5/12 text-center flex flex-col items-center justify-center font-Poppins ">
            <div>
              <p className="mb-3 font-extrabold text-3xl text-white">
                Core Values
              </p>
              <p className="mb-3">
                We Believe in investing in individuals <br /> who have{" "}
                <span className="text-white font-bold">PIE :</span>
              </p>
              <p>
                <span className="text-white font-bold"> P </span> - a{" "}
                <span className="text-white font-bold"> Passion </span> to
                promote growth and <br /> development <br />
                <span className="text-white font-bold"> I </span> -{" "}
                <span className="text-white font-bold"> Integrity </span> for
                innovation <br />
                <span className="text-white font-bold"> E </span> - An{" "}
                <span className="text-white font-bold"> Excellent </span> acumen
                for <br /> exceptional delivery
              </p>
            </div>
          </div>

          {/* The bar between */}
          <div className="w-2/12 flex justify-center items-center ">
            <div className="w-px bg-white h-80 "></div>
          </div>

          {/* Goals at the right hand side */}
          <div className="w-5/12  flex flex-col items-center justify-center mr-2">
            <p className="mb-3 font-bold text-3xl text-white">Goals</p>
            <div className="space-y-2 font-Poppins">
              <p>
                <span className="text-white font-bold">1.</span> To Promote
                innovation through Education, Engineering and Entrepreneurship
              </p>
              <p>
                <span className="text-white font-bold">2.</span> To develop and
                evaluate STEM Projects and Programs for K-12 Students{" "}
              </p>
              <p>
                <span className="text-white font-bold">3.</span> To Organize
                Competitions for the Youth
              </p>
              <p>
                <span className="text-white font-bold">4.</span> Implement STEM
                initiatives through partnership with like-minded Organisations
              </p>
              <p>
                <span className="text-white font-bold">5.</span> Develop STEM
                Toolkits for Teaching K-12
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Core values and goals for Small Screens only (The Function)
function CVAGSmallScreens() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [image2Loaded, setImage2Loaded] = useState(false);

  // So imediately the page refreshes the use effect actiohn will ran.
  useEffect(() => {
    const img = new Image();
    const img2 = new Image();
    img.src =
      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2Fblurry-corevalues-and-goals.png?alt=media&token=b21d3651-a137-44df-87bb-54aa715314ba";
    img2.src =
      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSEEDAFBoys-on-a-bicycle-blur.png?alt=media&token=732b5c53-d543-4811-955c-1fe7130f687e";
    // When the image is fully loaded, set imageLoaded to true
    img.onload = () => {
      setImageLoaded(true);
    };
    img2.onload = () => {
      setImage2Loaded(true);
    };
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div className="md:hidden visible justify-between items-center px-4 ">
      {/* Core Value */}
      <div className="sm:mx-4 rounded-xl my-5 text-center text-paleGreen mb-8 relative">
        {imageLoaded ? (
          <img
            className="object-cover w-full h-[250px] rounded-xl"
            src="https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2Fblurry-corevalues-and-goals.png?alt=media&token=b21d3651-a137-44df-87bb-54aa715314ba"
          />
        ) : (
          <div className="object-cover overflow-hidden w-full h-[250px] rounded-xl">
            <Blurhash
              hash="]C8|t-bHRjxuoe~WWCWBxujs.7ofWBoLjZ%Lt6ofWBR*t7ofoeRjayoLbHofRjafj[oLayaya|ofj[WBayayazWBWVoLj[" // String for the blurry core values and goals.
              width={"100%"}
              height={"100%"} // Adjust height to match the height of the actual image
              resolutionX={32}
              resolutionY={32}
              punch={1}
            />
          </div>
        )}

        <div className=" absolute inset-0 ">
          <p className="text-lg font-Poppins sm:text-2xl font-bold mb-2 mt-5 text-white">
            Core Values
          </p>
          <p className="text-sm font-Poppins sm:text-lg mb-5 leading-6">
            We Believe in investing in individuals <br /> who have{" "}
            <span className="font-bold text-white"> PIE :</span>
            <br />
            <span className="font-bold text-white">P</span> - a{" "}
            <span className="font-bold text-white">Passion</span> to promote
            growth <br /> and development <br />
            <span className="font-bold text-white">I</span> -{" "}
            <span className="font-bold text-white">Integrity</span> for
            innovation <br />
            <span className="font-bold text-white">E</span> - An{" "}
            <span className="font-bold text-white">Excellent</span> acumen for
            exceptional delivery
          </p>
        </div>
      </div>

      {/* Goals */}
      <div className="sm:mx-4 rounded-xl my-5 text-paleGreen mb-8 relative ">
        {image2Loaded ? (
          <img
            className="object-cover w-full h-[430px] rounded-xl"
            src="https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSEEDAFBoys-on-a-bicycle-blur.png?alt=media&token=732b5c53-d543-4811-955c-1fe7130f687e"
          />
        ) : (
          <div className="object-cover overflow-hidden w-full h-[450px] rounded-xl">
            <Blurhash
              hash="]27-E04;I:-qR37OXnIpxu9E_NE+bws.%0pdJCkERjxZ?wNeW=t7Riu5NbR*o#RP%$S5bbkCV?tmW=bHoLRPx]xuWBWBoe" // String for the blurry core values and goals.
              width={"100%"}
              height={"100%"} // Adjust height to match the height of the actual image
              resolutionX={32}
              resolutionY={32}
              punch={1}
            />
          </div>
        )}
        <div className="absolute inset-0 flex flex-col justify-center items-center p-4">
          <p className="text-lg font-Poppins sm:text-2xl font-bold text-center text-white">
            Goals
          </p>
          <div className="">
            <p className="text-sm font-Poppins mb-5 mt-2 ">
              <span className="font-bold text-white text-left">1.</span> To
              Promote innovation through Education, Engineering and
              Entrepreneurship <br />
              <br />
              <span className="font-bold text-white">2.</span> To develop and
              evaluate STEM Projects and Programs for K-12 Students <br />
              <br />
              <span className="font-bold text-white">3.</span> To Organize
              Competitions for the Youth <br />
              <br />
              <span className="font-bold text-white">4.</span> Implement STEM
              initiatives through partnership with like-minded Organisations
              <br />
              <br />
              <span className="font-bold text-white">5.</span> Develop STEM
              Toolkits for Teaching K-12
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
