import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo/SeedAfrique Org Logo.png";
import CloseNavBarButton from "../assets/hompageImages/CloseNavBarButton.png";
import OpenNavBarButton from "../assets/hompageImages/Nav Bar Button.png";

export default function Header() {
  return (
    <>
      <TopNavBar />
      <div className="md:h-0.5 md:mx-8 md:bg-black"></div>
    </>
  );
}

const TopNavBar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      {isSidebarOpen && (
        <div
          className="fixed top-0 left-0 h-screen w-screen bg-green-950 opacity-50 z-40 backdrop-filter backdrop-blur-lg"
          onClick={toggleSidebar}
        ></div>
      )}

{/* Mobile */}
      <div className="flex justify-center">
        <div
          className={`fixed rounded-l-lg top-2 bottom-2 right-0 h-full w-3/4 bg-paleGreen z-50 overflow-y-auto ${
            isSidebarOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out`}
        >
          <div className="p-4">
            <div>
              <div className="flex justify-end">
                <img
                  src={CloseNavBarButton}
                  alt="Menu"
                  className="md:h-6 md:w-6 h-4 w-4 mt-2 mb-5"
                  onClick={toggleSidebar}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <Link to="/home" onClick={toggleSidebar} className="">
                <div className="block text-center text-gray-500 hover:text-paleGreen py-2 hover:bg-green-600 hover:rounded-2xl hover:border-2 hover:border-gray-400">
                  Home
                </div>
              </Link>
              <Link to="/about" onClick={toggleSidebar} className="">
                <div className="block text-center text-gray-500 hover:text-paleGreen py-2 hover:bg-green-600 hover:rounded-2xl hover:border-2 hover:border-gray-400">
                  About Us
                </div>
              </Link>
              <Link to="/gallery" onClick={toggleSidebar} className="">
                <div className="block text-center text-gray-500 hover:text-paleGreen py-2 hover:bg-green-600 hover:rounded-2xl hover:border-2 hover:border-gray-400">
                  Gallery
                </div>
              </Link>
              <Link to="/blog" onClick={toggleSidebar} className="">
                <div className="block text-center text-gray-500 hover:text-paleGreen py-2 hover:bg-green-600 hover:rounded-2xl hover:border-2 hover:border-gray-400">
                  Blog
                </div>
              </Link>
              <Link to="/contacts" onClick={toggleSidebar} className="">
                <div className="block text-center text-gray-500 hover:text-paleGreen py-2 hover:bg-green-600 hover:rounded-2xl hover:border-2 hover:border-gray-400">
                  Contact Us
                </div>
              </Link>
              <Link
                to="/admin"
                onClick={toggleSidebar}
                className="block text-center text-gray-500 hover:text-paleGreen py-2 hover:bg-green-600 hover:rounded-2xl hover:border-2 hover:border-gray-400"
              >
                Admin
              </Link>
            </div>
          </div>
        </div>
      </div>

{/* Desktop */}
      <nav className="md:bg-gradient-to-r md:from-transparent md:to-transparent bg-gradient-to-r from-neutral-800 to-emerald-500 md:py-0 md:px-2 p-2 flex justify-between items-center">
        <div className="flex items-center">
          <img
            src={logo}
            alt="Logo"
            className="md:h-24 md:w-24 h-10 w-10 ml-4"
            onClick={toggleSidebar}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="hidden md:flex items-center space-x-4 mr-6">
        <Link to="/home" className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out">
  Home
</Link>
<Link to="/about" className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out">
  About Us
</Link>
<Link to="/gallery" className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out">
  Gallery
</Link>
<Link to="/blog" className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out">
  Blog
</Link>
<Link to="/contacts" className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out">
  Contact Us
</Link>
<Link to="/admin" className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out">
  Admin
</Link>
        </div>
        <div className="md:hidden flex justify-end items-center">
          <img
            src={OpenNavBarButton}
            alt="Menu"
            className="h-4 w-4"
            onClick={toggleSidebar}
            style={{ cursor: "pointer" }}
          />
        </div>
      </nav>
    </div>
  );
};
