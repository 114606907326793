// All image imports
import blurCVAGB from "../images/blurry-corevalues-and-goals.png";
import eDzata from "../images/aboutpgimgs/Dzata Emma.jpg";
import eObodai from "../images/aboutpgimgs/EmmanuelObodai.jpg";
import img1 from '../assets/gallerypageimages/img1.jpg';
import img2 from '../assets/gallerypageimages/img2.jpg';
import img3 from '../assets/gallerypageimages/img3.jpeg';
import img4 from '../assets/gallerypageimages/img4.jpg';
import img5 from '../assets/gallerypageimages/img5.jpg';
import img6 from '../assets/gallerypageimages/img6.jpg';
import img7 from '../assets/gallerypageimages/img7.jpg';
import img8 from '../assets/gallerypageimages/img8.jpg';
import img9 from '../assets/gallerypageimages/img9.jpeg';
import img10 from '../assets/gallerypageimages/img10.jpeg';
import img11 from '../assets/gallerypageimages/img11.jpg';
import img12 from '../assets/gallerypageimages/img12.jpeg';

const GalleryData = [
  // Sport category with multiple images
  {
    title: "Osagefo Criterium",
    category: "Sport",
    images: [
      { imageUrl: "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/gallery-images%2FIEEE%20BG%20Visitor%20or%20Admim%20Darker%202.png?alt=media&token=0618a3da-d536-4f3c-8b41-bfa6ccacc7d1", blurHash: "" },
      { imageUrl: img1, blurHash: "" },
      { imageUrl: img2, blurHash: "" },
      { imageUrl: eObodai, blurHash: "" },
      { imageUrl: img5, blurHash: "" },
      { imageUrl: img6, blurHash: "" },
      { imageUrl: eDzata, blurHash: "" },
      { imageUrl: img3, blurHash: "" },
      { imageUrl: img4, blurHash: "" },
    ],
    eventDate: "2023-08-15",
  },
  // STEM category with multiple images
  {
    title: "IEEE Conference",
    category: "STEM",
    images: [
      { imageUrl: eDzata, blurHash: "" },
      { imageUrl: img3, blurHash: "" },
      { imageUrl: img4, blurHash: "" },
      { imageUrl: blurCVAGB, blurHash: "" },
      { imageUrl: img1, blurHash: "" },
      { imageUrl: img2, blurHash: "" },
      { imageUrl: eDzata, blurHash: "" },
      { imageUrl: img3, blurHash: "" },
      { imageUrl: img4, blurHash: "" },

    ],
    eventDate: "2023-05-22",
  },
  // STEM category with mixed images
  {
    title: "University Of Ghana Workshop",
    category: "STEM",
    images: [
      { imageUrl: eObodai, blurHash: "" },
      { imageUrl: img5, blurHash: "" },
      { imageUrl: img6, blurHash: "" },
      { imageUrl: blurCVAGB, blurHash: "" },
      { imageUrl: img1, blurHash: "" },
      { imageUrl: img2, blurHash: "" },
      { imageUrl: blurCVAGB, blurHash: "" },
      { imageUrl: img1, blurHash: "" },
      { imageUrl: img2, blurHash: "" },
    ],
    eventDate: "2023-09-12",
  },
  // Another event in Sport category with new images
  {
    title: "Inter-College Football",
    category: "Sport",
    images: [
      { imageUrl: img7, blurHash: "" },
      { imageUrl: img8, blurHash: "" },
      { imageUrl: img9, blurHash: "" },
      { imageUrl: eObodai, blurHash: "" },
      { imageUrl: img5, blurHash: "" },
      { imageUrl: img6, blurHash: "" },
      { imageUrl: eDzata, blurHash: "" },
      { imageUrl: img3, blurHash: "" },
      { imageUrl: img4, blurHash: "" },
    ],
    eventDate: "2023-07-10",
  },
  // Art and Culture category with multiple images
  {
    title: "Cultural Exhibition",
    category: "Art and Culture",
    images: [
      { imageUrl: img10, blurHash: "" },
      { imageUrl: img11, blurHash: "" },
      { imageUrl: img12, blurHash: "" },
      { imageUrl: img9, blurHash: "" },
      { imageUrl: eObodai, blurHash: "" },
      { imageUrl: img5, blurHash: "" },
      { imageUrl: blurCVAGB, blurHash: "" },
      { imageUrl: img1, blurHash: "" },
      { imageUrl: img2, blurHash: "" },
    ],
    eventDate: "2023-04-05",
  },
];

export default GalleryData;
