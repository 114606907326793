// ViewerOrAdmin.js
import { img } from "framer-motion/client";
import React, { useEffect, useState } from "react";
import { Blurhash } from "react-blurhash";

// Icons
import { FaRegUser } from "react-icons/fa";
import { MdOutlineAdminPanelSettings } from "react-icons/md";

const ViewerOrAdmin = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src =
      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Assets%2FIEEE%20BG%20Visitor%20or%20Admim%20Darker%202.png?alt=media&token=5f4020e5-6251-45a5-918a-d33dd7455de0";
    img.onload = () => {
      setImageLoaded(true);
    };
  });

  return (
    // Parent
    <div className="flex flex-col items-center justify-center h-screen relative">
      {/* Image */}
      {imageLoaded ? (
        <img
          className="object-cover h-full w-full"
          src="https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Assets%2FIEEE%20BG%20Visitor%20or%20Admim%20Darker%202.png?alt=media&token=5f4020e5-6251-45a5-918a-d33dd7455de0"
        />
      ) : (
        <Blurhash
          // You use the dived hash when you want to specify the sizing
          hash="L3BD7}rB4mTM00Mx?wMbDNT2NF%L"
          width={"100%"}
          height={"100%"}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      )}

      {/* Child for overlay */}
      <div className="absolute inset-0 flex flex-col items-center justify-center h-screen">
        <h1
          className="sm:text-2xl font-bold mb-4 mx-1 text-lg text-inputColour font-Poppins shadow-2xl shadow-black rounded-lg sm:px-4 px-2 border-4 border-inputColour"
          style={{ textShadow: "0px 0px 10px rgba(0, 0, 0, 0.9)" }}
        >
          ARE YOU ADMIN OR A VISITOR ?
        </h1>
        <div className="flex">
          <a href="/admin">
            <div className="px-4 py-2 transition ease-in-out duration-500 transform bg-blue-900 hover:scale-110 text-white   rounded mr-4 font-Poppins flex justify-center items-center space-x-1">
              <MdOutlineAdminPanelSettings className="w-6 h-6" />
              <p>ADMIN</p>
            </div>
          </a>
          <a href="/home">
            <div className="px-4 py-2 transition duration-500 ease-in-out transform bg-green-900 hover:scale-110  rounded text-white   font-Poppins flex justify-center items-center space-x-1">
              <FaRegUser className="w-6 h-6" />
              <p>VISITOR</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ViewerOrAdmin;
