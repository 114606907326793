import React, {useState} from 'react';
import facebookicon from "../assets/hompageImages/Social Media Icons/ic_baseline-facebook.png";
import instagramicon from "../assets/hompageImages/Social Media Icons/mdi_instagram.png";
import twittericon from "../assets/hompageImages/Social Media Icons/simple-icons_x.png";
import linkedinicon from "../assets/hompageImages/Social Media Icons/mdi_linkedin.png";




    const Contacts = () => {

      const [submitted, setSubmitted] = useState(false);
      const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.target;
      
        const formData = new FormData(form);
      
        try {
          const response = await fetch('https://formspree.io/f/myzgbwdz', {
            method: 'POST',
            body: formData,
            headers: {
              'Accept': 'application/json'
            }
          });
      
          if (response.ok) {
            setSubmitted(true);
            form.reset();
          } else {
            alert('There was a problem with your submission.');
          }
        } catch (error) {
          alert('There was a problem with your submission.');
        }
      };
      

  return (
        <>
                {/* SMALL SCREEN VIEW */}
          <div className='md:hidden flex flex-col justify-center items-center rounded-3xl mt-7 w-auto '>
                {/* DIV FOR THE HEADING OF SMALL SCREENS */}
          <div className='md:invisible '> <p className='font-Poppins font-bold text-4xl mb-7 drop-shadow-2xl '>Contact Us</p> </div>

          <div className='md:invisible  bg-cardButtonWhite w-10/12 rounded-3xl  h-full shadow-2xl'>   {/*  DIV FOR THE MAIN CONTENTS OF THE SMALL SCREENS */}
              <div className='md:invisible  bg-loginButtonColour rounded-t-3xl flex flex-col justify-center h-48 '>
                    <p className='font-Poppins text-[#3E5646] leading-9 ml-5 drop-shadow-lg'>Hey there,</p>
                    <span className='font-bold text-3xl font-Poppins ml-5  '>Lets get in touch</span>
                    {/* DIV AND SPAN OF SOCIAL MEDIA ICONS FOR SMALL SCREENS */}
                <div className='flex justify-center overflow-hidden '>
            
                      <div className='flex justify-center border-2 border-[#CEDAB9] w-44 h-10 rounded-3xl mt-7 overflow-clip relative'>
                      {/* I was tryigng to add a span over the div that contains the links, to achive this i had to make the parent divs relative and the span absolute, then i add the transform property group translate, i first of all added the "group" keyword to the div holding the span and the icons */}
                        <div className='w-full flex justify-around items-center overflow-x-hidden group'>
                    <span className='absolute z-20 bg-[#CEDAB9] w-44 h-10 flex justify-center items-center font-Poppins font-medium text-xl rounded-full transform transition-transform duration-1000 ease-in-out group-hover:-translate-x-96  ]'>Follow Us!</span>
                      {/* FACEBOOK */}
                    <a href='https://www.facebook.com/seedafrique?mibextid=LQQJ4d' alt='Our Facebook' target="_blank" rel="noopener noreferrer"><img src={facebookicon} alt='facebookLogo'  className='z-10 size-6 transition-transform duration-300 ease-in-out transform hover:scale-125' /> </a>
                    {/* INSTAGRAM */}
                    <a href='https://www.instagram.com/seedafrique?igsh=MXI4ZXIycmZqbzZjdA==' target='_blank' rel='noopener noreferrer'><img src={instagramicon} alt='instagramLogo' className='z-10 size-6 transition-transform duration-300 ease-in-out transform hover:scale-125' /></a>
                    {/* TWITTER */}
                    <a href='https://x.com/seedafrique?s=21' target='_blank' rel='noopener noreferrer'><img src={twittericon} alt='X-logo' className='z-10 size-6 transition-transform duration-300 ease-in-out transform hover:scale-125' /></a>
                    {/* LINKEDIN */}
                    <a href='https://www.linkedin.com/company/seedafrique/' target='_blank' rel='noopener noreferrer'><img src={linkedinicon} alt='linkedinLogo' className='z-10 size-6 transition-transform duration-300 ease-in-out transform hover:scale-125' /></a>
                    </div>
                  </div>
                </div>

              </div>
                {/* DIV HOLDING ALL THE INPUT FIELDS AND THEIR TITLES  OF THE SMALL SCREENS*/}
                <form id="contact-form" onSubmit={handleSubmit}>

              <div className='md:invisible bg-cardButtonWhite  grid grid-cols-1 font-Poppins h-max rounded-b-3xl p-9'>
                      {/* A SUB DIV THAT GROUPS THE Firstname, Surname and their input fields for the small screens */}
                  <div className='grid grid-cols-1 content-start gap-2 mb-6 text-sm'>
                    <p >Firstname</p>
                    <input id='firstname' name='firstname' type='text' placeholder='Emmanuel' className='border-0 border-b  border-b-black border-solid text-start '  />
                    <p>Surname</p>
                    <input id='surname' name='surname' type='text' placeholder='Akpalu' className='border-0 border-b border-b-black border-solid text-start'/>

                  </div>
                              {/* A SUB DIV THAT GROUPS THE Email, Phone number and their input fields */}
                  <div className='grid grid-cols-1 content-start gap-2 mb-6 text-sm'>
                    <p>Email</p>
                    <input id='email' name='email' type='email' placeholder='example@gmail.com' className='border-0 border-b border-b-black border-solid text-start' />
                    <p>Phone</p>
                    <input id='phone' name='phone' type='text' placeholder='0********71' className='border-0 border-b border-b-black border-solid text-start'/>

                  </div>
                              {/* A SUB DIV THAT GROUPS THE Message and it  input fields */}
                  <div className='grid grid-cols-1 content-start gap-2 '>
                    <p>Message</p>
                    <input id='message' name='message' type='text' placeholder='Hello, i have a ...'  className='border-0 border-b border-b-black border-solid text-start font-Poppins ' />

                  </div>
              </div>
                    {/* SUBDIV HOLDING THE CONTACT US BUTTON OF THE SMALL SCREEN */}
              <div className='text-center m-8'>
                <button type='submit' className='bg-loginButtonColour w-36 h-9 size-6 rounded-2xl text-[#3E5646] font-Poppins  leading-9 hover:bg-green-500'>Contact Us</button>
              </div>
                            {/* THANK YOU MESSAGE FOR SMALL SCREENS */}
                            {submitted && (
                                <div className='text-center mt-8'>
                                  <p className='text-green-500 font-Poppins font-bold'>Thank you for reaching out! We will get back to you soon.</p>
                                </div>
                              )}


            </form>
              </div>
            </div>
          
        {/* WHERE I STARTED CODING FOR BIG SCREENS */}

    <div className='hidden  md:flex md:justify-center'>
    <div className="cards-container invisible md:visible bg-buttonColor flex  items-center w-8/12 h-auto relative rounded-3xl shadow-2xl  mt-4 ">
                  {/* DIV FOR THE LEFT CONTENTS OF THE CARD */}
        <div className='invisible md:visible greenBox grid place-content-center   w-5/12 h-full  rounded-tl-3xl rounded-bl-3xl space-y-10 '>
          <div className='invisible md:visible mt-20 align-middle'>         {/*  DIV FOR TEXTS */}
              <p className='font-Poppins text-[#3E5646] leading-9 ml-5'>Hey There,</p>
              <p className='font-bold text-3xl font-Poppins ml-5 '>Lets get in touch</p>
          </div>
              {/* DIV FOR SOCIAL MEDIA ICONS */}
          <div className='flex justify-center overflow-hidden '>
            
                <div className='flex justify-center border-2 border-[#CEDAB9] w-44 h-10 rounded-3xl mt-16 overflow-clip relative'>
                  {/* I was tryigng to add a span over the div that contains the links, to achive this i had to make the parent divs relative and the span absolute, then i add the transform property group translate, i first of all added the "group" keyword to the div holding the span and the icons */}
                    <div className='w-full flex justify-around items-center overflow-x-hidden group'>
                    <span className='absolute z-50 bg-[#CEDAB9] w-44 h-10 flex justify-center items-center font-Poppins font-medium text-xl rounded-full transform transition-transform duration-1000 ease-in-out group-hover:-translate-x-96  ]'>Follow Us!</span>
                      {/* FACEBOOK */}
                        <a href='https://www.facebook.com/seedafrique?mibextid=LQQJ4d' alt='Our Facebook' target="_blank" rel="noopener noreferrer"><img src={facebookicon} alt='facebookLogo'  className='z-10 size-6 transition-transform duration-300 ease-in-out transform hover:scale-125' /> </a>
                        {/* INSTAGRAM */}
                        <a href='https://www.instagram.com/seedafrique?igsh=MXI4ZXIycmZqbzZjdA==' target='_blank' rel='noopener noreferrer'><img src={instagramicon} alt='instagramLogo' className='z-10 size-6 transition-transform duration-300 ease-in-out transform hover:scale-125' /></a>
                        {/* TWITTER */}
                        <a href='https://x.com/seedafrique?s=21' target='_blank' rel='noopener noreferrer'><img src={twittericon} alt='X-logo' className='z-10 size-6 transition-transform duration-300 ease-in-out transform hover:scale-125' /></a>
                        {/* LINKEDIN */}
                        <a href='https://www.linkedin.com/company/seedafrique/' target='_blank' rel='noopener noreferrer'><img src={linkedinicon} alt='linkedinLogo' className='z-10 size-6 transition-transform duration-300 ease-in-out transform hover:scale-125' /></a>
                    </div>
                </div>
          </div>
        </div>


        {/* DIV FOR THE RIGHT CONTENTS OF THE CARD */}
      <div className='bg-cardButtonWhite h-full w-7/12 rounded-tr-3xl rounded-br-3xl font-Poppins p-7 space-y-4'>
              <div className='invisible md:visible drop-shadow-xl'> <p className='invisible md:visible font-Poppins font-bold text-4xl text-teal-950'>Contact Us</p> 
              </div>
            
        
        <form id='contact form' onSubmit={handleSubmit}> 

          <div>   {/*   PARENT DIV HOLDING ALL THE INPUTS AND THEIR TITLES */}
                  {/* A SUB DIV THAT GROUPS THE Firstname, Surname and their input fields */}
                  <div className='grid grid-cols-2 content-start gap-2 mb-6'>
                    <p >Firstname</p>
                    <p>Surname</p>
                    <input id='firstname' name='firstname' type='text' placeholder='Emmanuel' className='border-0 border-b  border-b-black border-solid text-start '  />
                    <input id='surname' name='surname' type='text' placeholder='Akpalu' className='border-0 border-b border-b-black border-solid text-start'/>

                  </div>
                              {/* A SUB DIV THAT GROUPS THE Email, Phone number and their input fields */}
                  <div className='grid grid-cols-2 content-start gap-2 mb-6'>
                    <p>Email</p>
                    <p>Phone</p>
                    <input id='email' name='email' type='email' placeholder='example@gmail.com' className='border-0 border-b border-b-black border-solid text-start' />
                    <input id='phone' name='phone' type='text'  placeholder='0********71' className='border-0 border-b border-b-black border-solid text-start'/>

                  </div>
                              {/* A SUB DIV THAT GROUPS THE Message and it  input fields */}
                  <div className='grid grid-cols-1 content-start gap-2  mb-4'>
                    <p>Message</p>
                    <input id='message' name='message' type='text' placeholder='Hello, i have a ...'  className='border-0 border-b border-b-black border-solid text-start font-Poppins ' />

                  </div>
        </div>
              <div className='text-center'>
                <button type='submit' className='bg-loginButtonColour w-36 h-9 size-6 rounded-2xl text-[#3E5646] font-Poppins  leading-9   transition-transform duration-300 ease-in-out transform hover:scale-125'>Contact Us</button>
              </div>

                {/* THANK YOU MESSAGE FOR Big  SCREENS */}
                {submitted && (
                                <div className='text-center mt-8'>
                                  <p className='text-green-500 font-Poppins font-bold'>Thank you for reaching out! We will get back to you soon.</p>
                                </div>
                              )}
        </form>
            
      </div> 
      </div>
    </div>

        </>
      );
    };

export default Contacts;