import React, { useState, useEffect } from "react";
import axios from "axios";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../Firebase";

const ManageEvents = () => {
  const [events, setEvents] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: null,
    day: "",
    datenumber: 0,
    monthandyear: "",
    venue: "",
    category: "",
    eventDate: "",
    blurHash: "",
    imageUrl: "", // Added for Firebase image URL
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const uploadImageToFirebase = async (file) => {
    const storageRef = ref(storage, `event-images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress); // Track upload progress
        },
        (error) => {
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let imageUrl = formData.imageUrl;

      // If a new image file is selected, upload it to Firebase
      if (formData.image) {
        imageUrl = await uploadImageToFirebase(formData.image);
      }

      const eventData = {
        title: formData.title,
        description: formData.description,
        day: formData.day,
        datenumber: formData.datenumber,
        monthandyear: formData.monthandyear,
        venue: formData.venue,
        category: formData.category,
        eventDate: formData.eventDate,
        blurHash: formData.blurHash,
        imageUrl, // Use Firebase image URL
      };

      const apiCall = isEditing
        ? axios.put(
            `https://seedafriqueswegp-backend.onrender.com/api/events/${editId}`,
            eventData
          )
        : axios.post(
            "https://seedafriqueswegp-backend.onrender.com/api/events",
            eventData
          );

      apiCall
        .then((response) => {
          if (isEditing) {
            setEvents(
              events.map((event) =>
                event._id === editId ? response.data : event
              )
            );
            // Remove saved editId from localStorage after successful update
            localStorage.removeItem("editId");
          } else {
            setEvents((prevEvents) => [...prevEvents, response.data]);
          }
          resetForm();
          closeModal();
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.error("Error uploading image:", error);
      setLoading(false);
    }
  };

  const handleEdit = (event) => {
    console.log("Editing event:", event._id); // Check if we are getting the correct event ID

    setFormData({
      title: event.title,
      description: event.description,
      day: event.day,
      datenumber: event.datenumber,
      monthandyear: event.monthandyear,
      venue: event.venue,
      category: event.category,
      eventDate: event.eventDate,
      blurHash: event.blurHash,
      imageUrl: event.imageUrl, // Load the image URL for editing
      image: null, // Reset the image file
    });
    setEditId(event._id);
    setIsEditing(true);

    // Save the event ID to localStorage to persist the editing state
    localStorage.setItem("editId", event._id);
    console.log("Saved editId to localStorage:", event._id); // Check if it's saved

    openModal();
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://seedafriqueswegp-backend.onrender.com/api/events")
      .then((response) => {
        setEvents(response.data);

        // Check if there's a saved editId in localStorage
        const savedEditId = localStorage.getItem("editId");
        console.log("Retrieved editId from localStorage:", savedEditId); // Check if we get the editId
        if (savedEditId) {
          const eventToEdit = response.data.find(
            (event) => event._id === savedEditId
          );
          if (eventToEdit) {
            console.log("Event to edit after refresh:", eventToEdit); // Check if the event exists
            handleEdit(eventToEdit);
          }
        }
      })
      .catch((error) => console.error("Error fetching events:", error))
      .finally(() => setLoading(false));
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .delete(`https://seedafriqueswegp-backend.onrender.com/api/events/${id}`)
      .then(() => setEvents(events.filter((event) => event._id !== id)))
      .catch((error) => console.error("Error deleting event:", error))
      .finally(() => setLoading(false));
  };

  const resetForm = () => {
    setFormData({
      title: "",
      description: "",
      image: null,
      day: "",
      datenumber: 0,
      monthandyear: "",
      venue: "",
      category: "",
      eventDate: "",
      blurHash: "",
      imageUrl: "", // Reset the image URL
    });
    setIsEditing(false);
    setEditId(null);
  };

  const openModal = () => {
    document.body.classList.add("modal-open"); // Disable background scroll
    setIsModalOpen(true);
  };

  const closeModal = () => {
    document.body.classList.remove("modal-open"); // Re-enable background scroll
    setIsModalOpen(false);
  };

  return (
    <div className="manage-events mt-6">
      <div className="flex items-center ml-7">
        <span
          className="bg-buttonColor text-sm px-5 py-2 h-9 rounded-lg hover:bg-green-600"
          onClick={openModal}
        >
          {isEditing ? "EDIT EVENT" : "ADD EVENT"}
        </span>
        <div>
          <img
            src="/adminsidebarimages/basil_add-outline.png"
            className="h-11 cursor-pointer"
            alt="Add Icon"
            onClick={openModal}
          />
        </div>
      </div>

      {/* Loading Spinner */}
      {loading ? (
        <div className="flex justify-center items-center">
          <svg
            className="animate-spin h-10 w-10 text-green-600"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : (
        <div>
          <ManageEventsTemp
            events={events}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </div>
      )}

      {/* Modal for Adding/Editing Event */}
      {isModalOpen && (
        <div className=" fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-3 sm:p-8 rounded-lg w-full mx-4 sm:w-1/2 max-h-screen overflow-y-auto">
            <h2 className="text-xl mb-4">
              {isEditing ? "Edit Event" : "Add Event"}
            </h2>

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                placeholder="Event Title"
                style={{ border: "2px solid green", color: "black" }}
                className="text-base block w-full mb-2 p-2 border text-left rounded-md"
              />
              <input
                type="text"
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                placeholder="Category eg. STEM"
                style={{ border: "2px solid green", color: "black" }}
                className="text-base block w-full mb-2 p-2 border text-left rounded-md"
              />
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Event Description"
                style={{ border: "2px solid green", color: "black" }}
                className="text-base block w-full mb-2 p-2 border text-left rounded-md"
              />
              <input
                type="text"
                name="venue"
                value={formData.venue}
                onChange={handleInputChange}
                placeholder="Venue eg. Accra"
                style={{ border: "2px solid green", color: "black" }}
                className="text-base block w-full mb-2 p-2 border text-left rounded-md"
              />
              <input
                type="text"
                name="day"
                value={formData.day}
                onChange={handleInputChange}
                placeholder="Day eg. Mon"
                style={{ border: "2px solid green", color: "black" }}
                className="text-base block w-full mb-2 p-2 border text-left rounded-md"
              />
              <input
                type="number"
                name="datenumber"
                value={formData.datenumber}
                onChange={handleInputChange}
                placeholder="Date Number eg. 16"
                style={{ border: "2px solid green", color: "black" }}
                className="text-base block w-full sm:w-16 mb-2 p-2 text-left rounded-md"
              />

              <input
                type="text"
                name="monthandyear"
                value={formData.monthandyear}
                onChange={handleInputChange}
                placeholder="Month and Year eg. Aug 2024"
                style={{ border: "2px solid green", color: "black" }}
                className="text-base block w-full mb-2 p-2 border text-left rounded-md"
              />
              <input
                type="text"
                name="eventDate"
                value={formData.eventDate}
                onChange={handleInputChange}
                placeholder="Event Date (YYYY-MM-DD)"
                style={{ border: "2px solid green", color: "black" }}
                className="text-base block w-full mb-2 p-2 border text-left rounded-md"
              />
              <input
                type="text"
                name="blurHash"
                value={formData.blurHash}
                onChange={handleInputChange}
                placeholder="BlurHash (Optional)"
                style={{ border: "2px solid green", color: "black" }}
                className="text-base block w-full mb-2 p-2 border text-left rounded-md"
              />
              <input
                type="file"
                name="image"
                onChange={handleImageChange}
                style={{
                  border: "2px solid green",
                  color: "black",
                  padding: "5px",
                }}
                className="text-base block w-full mb-4 rounded-md"
              />

              {uploadProgress > 0 && (
                <div>
                  <p className=" p-4 bg-cyan-600 rounded-xl text-gray-100 my-2">
                    Uploading:{" "}
                    <span className="font-bold text-orange-200">
                      {Math.round(uploadProgress)}%
                    </span>
                  </p>
                </div>
              )}

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={closeModal}
                  className="mr-4 text-red-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-buttonColor text-white px-5 py-2 rounded-lg hover:bg-green-600"
                  disabled={loading}
                >
                  {isEditing ? "Update Event" : "Submit Event"}
                  {loading && (
                    <svg
                      className="animate-spin h-5 w-5 text-white mx-auto"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageEvents;

// The Template that would be filled with the details from the API.
const ManageEventsTemp = ({ events, onEdit, onDelete }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 md:mr-2 mb-4">
      {Array.isArray(events) && events.length > 0 ? (
        events.map((item) => (
          <div
            key={item._id}
            className="rounded-2xl p-4 flex flex-col justify-center md:justify-between h-auto"
          >
            <div className="">
              <div
                className="w-full h-48 bg-cover bg-center mb-4 rounded-t-2xl"
                style={{
                  backgroundImage: `url('${item.imageUrl}')`,
                }}
              >
                <div className="flex justify-end">
                  <div className="rounded-xl border-4 border-green-950 p-2 mr-1 mt-1 bg-paleGreen">
                    <center>
                      <p className="text-xs text-green-950">{item.day}</p>
                    </center>
                    <center>
                      <p className="text-xs text-green-950 font-bold">
                        {item.datenumber}
                      </p>
                    </center>
                    <center>
                      <p className="text-xs text-green-950">
                        {item.monthandyear}
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <h2 className="rounded-md w-24 text-sm bg-green-800 text-lime-50 text-center mt-2 py-1">
                {item.category}
              </h2>
              <h1 className="text-lg font-bold text-green-950 mt-2">
                {item.title}
              </h1>
            </div>
            <div>
              <p className="text-emerald-900 mb-2 text-sm">
                {item.description}
              </p>
            </div>
            <div>
              <a className="text-emerald-900 mb-2 underline" href="#">
                {item.venue}
              </a>
            </div>
            <div className="flex justify-between mt-5">
              <button
                onClick={() => onEdit(item)}
                className=" text-gray-950 bg-gray-400 py-2 px-3 w-28 rounded-lg"
              >
                Edit
              </button>
              <button
                onClick={() => onDelete(item._id)}
                className="text-gray-950 bg-red-500 py-2 px-3 w-28 rounded-lg"
              >
                Delete
              </button>
            </div>
          </div>
        ))
      ) : (
        <p className="md:ml-7">No events available</p>
      )}
    </div>
  );
};
