import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/AboutUs";
import Gallery from "./pages/Gallery";
import Blog from "./pages/Blog";
import Contacts from "./pages/Contacts";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import Login from "./pages/Login";
import ViewerOrAdmin from "./pages/ViewerOrAdmin";

// Admin Pages
import ManageBlogs from "./pages/Admin/ManageBlogs";
import ManageEvents from "./pages/Admin/ManageEvents";
import ManageGallery from "./pages/Admin/Managegallery";
import ManageRequests from "./pages/Admin/ManageRequests";

import Footer from "./components/Footer";
import "./index.css";
import AdminSidebar from "./pages/Admin/AdminSidebar";
import { useAuth } from "./context/AuthContext"; // Import the AuthContext
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./Firebase";

function App() {
  const location = useLocation();
  const { currentUser, setCurrentUser } = useAuth(); // Access setCurrentUser to update the auth state
  const [loading, setLoading] = useState(true);

  const isAdminRoute = location.pathname.startsWith("/admin");
  const isAdminDashboardRoute = location.pathname.startsWith(
    "/admin/admindashboard-content/"
  );

  const isViewerOrAdminRoute = location.pathname === "/"; // Check if on the ViewerOrAdmin page

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false); // Stop loading when the auth state has been checked
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, [setCurrentUser]);

  if (loading) {
    // You can render a loading spinner here or a placeholder
    return (
      <div className="mt-10 flex justify-center items-center">
        <svg
          className="animate-spin h-10 w-10 text-green-500 mx-auto"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <div>
      {/* Conditionally render Navbar and Footer only when not on ViewerOrAdmin page */}
      {!isAdminRoute && !isViewerOrAdminRoute && <Navbar />}
      <div className={` ${isAdminDashboardRoute ? "" : ""}`}>
        {isAdminDashboardRoute && <AdminSidebar />}
        <main>
          <Routes>
            {/* Will display immediately when navigated to "/" */}
            <Route path="/" element={<ViewerOrAdmin />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/admin" element={<Login />} />
            <Route
              path="/admin/admindashboard"
              element={
                currentUser ? <AdminDashboard /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="/admin/admindashboard-content/manage-blogs"
              element={
                currentUser ? (
                  <div className="sm:ml-24">
                    <ManageBlogs />
                  </div>
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="/admin/admindashboard-content/manage-events"
              element={
                currentUser ? (
                  <div className="sm:ml-24">
                    <ManageEvents />
                  </div>
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="/admin/admindashboard-content/manage-gallery"
              element={
                currentUser ? (
                  <div className="sm:ml-24">
                    <ManageGallery />
                  </div>
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="/admin/admindashboard-content/manage-requests"
              element={
                currentUser ? (
                  <div className="sm:ml-24">
                    <ManageRequests />
                  </div>
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
          </Routes>
        </main>
      </div>
      {!isAdminRoute && !isViewerOrAdminRoute && <Footer />}
    </div>
  );
}

export default App;
