import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import React from "react";
import TopmostHeroText from "../components/homepageComponents/topMostText.js";
import GalleryOnHome from "../components/homepageComponents/GalleryOnHome.js";
import SeedAfriqueOrganization from "../components/homepageComponents/SeedAfriqueOrganization.js";
import UpcomingEventsOnHome from "../components/homepageComponents/UpcomingEventsOnHome.js";
import BlogOnHome from "../components/homepageComponents/BlogOnhome.js";
import JoinUs from "../components/homepageComponents/JoinUs.js";

const Home = () => {
  // Necessary for vchecking the locaton of Id's .....
  const location = useLocation();

  useEffect(() => {
    // This is to Check if there's a target section in the state
    if (location.state?.targetSection) {
      const section = document.getElementById(location.state.targetSection);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <TopmostHeroText />
      <GalleryOnHome />
      <SeedAfriqueOrganization />
      <UpcomingEventsOnHome />
      {/* <BlogOnHome /> */}

      {/* Ensure the target section has the div around it and the unique id */}
      <div id="join-us-section">
        <JoinUs />
      </div>
    </>
  );
};

export default Home;
