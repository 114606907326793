import React, { useState } from "react";
import GalleryData from "../data/galleryData";
import { GrFormNextLink } from "react-icons/gr";
import { GrFormPreviousLink } from "react-icons/gr";

const Gallery = () => {
  return (
    <div className="gallery-page p-3 sm:p-8">
      {/* Loop through each event in the gallery data */}
      {GalleryData.map((event, eventIndex) => (
        <EventGallery key={eventIndex} event={event} />
      ))}
    </div>
  );
};

const EventGallery = ({ event }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [animating, setAnimating] = useState(false); // For managing animations
  const [direction, setDirection] = useState("next"); // For handling opposite directions

  const totalImages = event.images.length;

  const prevImage = () => {
    if (currentImageIndex > 0) {
      setAnimating(true);
      setDirection("prev"); // Set direction to previous
      setTimeout(() => {
        setCurrentImageIndex(currentImageIndex - 1);
        setAnimating(false);
      }, 400); // Wait for animation duration before changing
    }
  };

  const nextImage = () => {
    if (currentImageIndex < totalImages - 1) {
      setAnimating(true);
      setDirection("next"); // Set direction to next
      setTimeout(() => {
        setCurrentImageIndex(currentImageIndex + 1);
        setAnimating(false);
      }, 400); // Wait for animation duration before changing
    }
  };

  const prevIndex = currentImageIndex > 0 ? currentImageIndex - 1 : null;
  const nextIndex =
    currentImageIndex < totalImages - 1 ? currentImageIndex + 1 : null;

  return (
    <div className="gallery-event mb-5 sm:mb-10 p-3 sm:p-6 bg-aboutbg shadow-lg rounded-lg">
      {/* Event details */}
      <h2 className="text-lg sm:text-2xl font-bold mb-2">{event.title}</h2>
      <p className="text-sm text-gray-600 mb-1">
        Category:{" "}
        <span className=" sm:text-ld text-xs bg-blue-400 px-2 py-1 sm:px-3 sm:py-2 rounded-lg font-Poppins font-bold text-gray-300">
          {event.category}
        </span>
      </p>
      <p className="text-sm text-gray-600 mb-8 sm:mb-4">
        Event Date: {event.eventDate || "No date provided"}
      </p>

      <div className="event-images relative h-40 sm:h-96 flex items-center justify-center">
        {/* Previous image */}
        {prevIndex !== null && (
          <div
            className={`absolute transform opacity-50 transition-all duration-500 ease-in-out ${
              animating && direction === "prev"
                ? "translate-x-0"
                : "-translate-x-1/2"
            }`}
          >
            <img
              src={event.images[prevIndex].imageUrl}
              alt={`${event.title} image ${prevIndex + 1}`}
              className="gallery-image h-28 sm:h-64 w-36 sm:w-72 object-cover rounded-md sm:rounded-lg"
            />
          </div>
        )}

        {/* Current image */}
        <div
          className={`relative z-10 transition-all duration-500 ease-in-out transform scale-100 ${
            animating
              ? direction === "next"
                ? "-translate-x-full opacity-0"
                : "translate-x-full opacity-0"
              : "opacity-100"
          }`}
        >
          <img
            src={event.images[currentImageIndex].imageUrl}
            alt={`${event.title} image ${currentImageIndex + 1}`}
            className="gallery-image h-48 sm:h-96 w-36 sm:w-96 object-cover rounded-md sm:rounded-lg"
          />
        </div>

        {/* Next image */}
        {nextIndex !== null && (
          <div
            className={`absolute transform opacity-50 transition-all duration-500 ease-in-out ${
              animating && direction === "next"
                ? "translate-x-0"
                : "translate-x-1/2"
            }`}
          >
            <img
              src={event.images[nextIndex].imageUrl}
              alt={`${event.title} image ${nextIndex + 1}`}
              className="gallery-image h-28 sm:h-64 w-36 sm:w-72 object-cover rounded-md sm:rounded-lg"
            />
          </div>
        )}

        {/* Navigation buttons positioned on sides */}
        <button
          onClick={prevImage}
          disabled={currentImageIndex === 0 || animating} // Disable during animation
          className="z-50 absolute left-1 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 pl-2 sm:px-4 rounded-full sm:rounded disabled:opacity-50 transition-all duration-300 ease-in-out hover:scale-110"
        >
          <GrFormPreviousLink className="mr-2" />
        </button>

        <button
          onClick={nextImage}
          disabled={currentImageIndex === totalImages - 1 || animating} // Disable during animation
          className="z-50 absolute right-1 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 pr-2 sm:px-4 rounded-full sm:rounded disabled:opacity-50 transition-all duration-300 ease-in-out hover:scale-110"
        >
          <GrFormNextLink className="ml-2" />
        </button>
      </div>

      {/* For easy tracking of the number of images */}
      <p className="font-bold sm:text-lg text-sm text-center mt-9 text-gray-500 w-full">
        <span className="text-white bg-blue-400 px-5 mr-1 rounded-md py-1">
          {currentImageIndex + 1}
        </span>
        / {totalImages}
      </p>
    </div>
  );
};

export default Gallery;
