import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios for making API calls
import GalleryData from "../../data/galleryData";
import { GrFormNextLink } from "react-icons/gr";
import { GrFormPreviousLink } from "react-icons/gr";
import { IoMdAddCircle } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { FaTrashCan } from "react-icons/fa6";
import { MdOutlineDeleteForever } from "react-icons/md";

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../Firebase";

// The main page
const ManageGallery = () => {
  const [addingEvent, setAddingEvent] = useState(false);
  const [formData, setFormData] = useState({
    id: "", // Event ID for editing
    title: "",
    category: "",
    eventDate: "",
    blurHash: "",
    image: null, // Will hold the image file for uploading
  });
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingImages, setIsAddingImages] = useState(false); // To differentiate between adding event and adding images
  const [galleryData, setGalleryData] = useState([]); // State to hold fetched gallery data

  // Fetch gallery data from the API when the component mounts
  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        const response = await axios.get(
          "https://seedafriqueswegp-backend.onrender.com/api/gallery"
        ); // Replace with your actual API endpoint
        setGalleryData(response.data); // Set the fetched data
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      }
    };

    fetchGalleryData(); // Fetch data on component mount
  }, []);

  // Function to upload image to Firebase Storage and return the download URL
  const uploadImageToFirebase = (file) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        return reject("No file provided");
      }

      const storageRef = ref(storage, `gallery-images2/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // You can track progress here if you want to
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error("Error uploading image to Firebase:", error);
          reject(error);
        },
        () => {
          // When the upload is complete, get the download URL
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              console.log("File available at:", downloadURL);
              resolve(downloadURL); // Return the download URL
            })
            .catch((error) => {
              console.error("Error getting download URL:", error);
              reject(error);
            });
        }
      );
    });
  };

  // Handle form submission to create a new event
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file, // Store the selected image file
    }));
    console.log("Selected image file:", file);
  };

  // Function to start editing an event (populate the form, no API call yet)
  const handleEditEvent = (event) => {
    setFormData({
      id: event._id, // Store event ID for the update
      title: event.title,
      category: event.category,
      eventDate: event.eventDate,
      blurHash: event.blurHash,
      image: null, // Don't allow image changes here
    });
    setIsEditing(true); // Enable editing mode
    setAddingEvent(true); // Show the form modal
  };

  // Handle form submission to update an event
  // Handle form submission for creating or updating an event
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (isEditing) {
        // Update an existing event (PUT request)
        const updatedEvent = {
          title: formData.title,
          category: formData.category,
          eventDate: formData.eventDate,
          blurHash: formData.blurHash || "",
        };

        const response = await axios.put(
          `https://seedafriqueswegp-backend.onrender.com/api/gallery/${formData.id}`,
          updatedEvent
        );
        console.log("Event updated successfully:", response.data);

        // Update the gallery data state with the updated event
        setGalleryData((prevData) =>
          prevData.map((event) =>
            event._id === formData.id ? response.data : event
          )
        );
      } else {
        // Create a new event (POST request)
        const imageUrl = await uploadImageToFirebase(formData.image);
        console.log("Image uploaded to Firebase. URL:", imageUrl);

        const newEvent = {
          title: formData.title,
          category: formData.category,
          eventDate: formData.eventDate,
          images: [{ imageUrl }], // Add the Firebase image URL
          blurHash: formData.blurHash || "",
        };

        const response = await axios.post(
          "https://seedafriqueswegp-backend.onrender.com/api/gallery",
          newEvent
        );
        console.log("Event created successfully:", response.data);

        // Add the new event to the gallery data state
        setGalleryData([...galleryData, response.data]);
      }

      setAddingEvent(false); // Close the form modal
      setLoading(false); // Stop loading spinner
      setIsEditing(false); // Reset editing state
    } catch (error) {
      console.error("Error during form submission:", error);
      setLoading(false); // Stop loading spinner on error
    }
  };

  // Function to open form for adding new images to a category
  const handleAddImagesToCategory = (category) => {
    setFormData({
      title: "", // No need for title when adding images
      category: category, // Add images to the selected category
      eventDate: "",
      blurHash: "",
      image: null,
    });
    setIsEditing(false); // Not editing an event
    setIsAddingImages(true); // We're adding images
    setAddingEvent(true); // Show the form modal for adding images
  };

  // Function to delete an event from the database
  const handleDeleteEvent = async (eventId) => {
    try {
      const response = await axios.delete(
        `https://seedafriqueswegp-backend.onrender.com/api/gallery/${eventId}`
      );
      console.log("Event deleted successfully:", response.data);

      // Update the gallery data after deleting the event
      setGalleryData((prevData) =>
        prevData.filter((event) => event._id !== eventId)
      );
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  // Function to delete a specific image from an event's image array
  // Function to delete a specific image from an event's image array
  const handleDeleteImage = async (eventId, imageUrl) => {
    try {
      const response = await axios.put(
        `https://seedafriqueswegp-backend.onrender.com/api/gallery/${eventId}/delete-image`,
        { imageUrl }
      );
      console.log("Image deleted successfully:", response.data);

      // Update the gallery data after deleting the image
      setGalleryData((prevData) =>
        prevData.map((event) =>
          event._id === eventId
            ? { ...event, images: response.data.galleryEvent.images } // Update images
            : event
        )
      );
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  return (
    <div className="manage-events ml-4 mt-6">
      {/* Top section */}
      <div className="flex items-center">
        <button
          className="bg-buttonColor text-sm px-5 py-2 h-9 rounded-lg hover:bg-green-600"
          onClick={() => {
            setFormData({
              id: "", // Ensure ID is empty for new events
              title: "",
              category: "",
              eventDate: "",
              blurHash: "",
              image: null,
            });
            setIsEditing(false); // Ensure this is set to false when adding a new event
            setIsAddingImages(false); // Not adding images, just adding a new event
            setAddingEvent(true); // Open the form modal for new event
          }}
        >
          NEW EVENT
        </button>
        {/* Button Image */}
        <div>
          <img
            src="/adminsidebarimages/basil_add-outline.png"
            className="h-11"
            alt="Add Event"
          />
        </div>
      </div>

      {/* The main content with filled template */}
      <div>
        <ManageGalleryTemp
          galleryData={galleryData} // Pass the fetched data to child component
          onEditEvent={handleEditEvent}
          onAddImages={handleAddImagesToCategory}
          onDeleteEvent={handleDeleteEvent}
          onDeleteImage={handleDeleteImage}
        />
      </div>

      {/* If i am adding ans event this is conditionallly rendered */}
      {addingEvent ? (
        <div className="z-[51] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-3 sm:p-8 rounded-lg w-full mx-4 sm:w-1/2 max-h-screen overflow-y-auto">
            <h2 className="text-xl mb-4">
              {isEditing
                ? "Edit Event"
                : isAddingImages
                ? "Add Images to " + formData.category
                : "Add New Event"}
            </h2>

            <form onSubmit={handleSubmit}>
              {/* Only show title and date when adding/editing an event */}
              {!isAddingImages && (
                <>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    placeholder="Event Title"
                    style={{ border: "2px solid green", color: "black" }}
                    className="text-base block w-full mb-2 p-2 border text-left rounded-md"
                  />
                  <input
                    type="text"
                    name="eventDate"
                    value={formData.eventDate}
                    onChange={handleInputChange}
                    placeholder="Event Date (YYYY-MM-DD)"
                    style={{ border: "2px solid green", color: "black" }}
                    className="text-base block w-full mb-2 p-2 border text-left rounded-md"
                  />
                </>
              )}
              {/* Always show category and image fields */}
              <input
                type="text"
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                placeholder="Category"
                style={{ border: "2px solid green", color: "black" }}
                className="text-base block w-full mb-2 p-2 border text-left rounded-md"
                disabled={isAddingImages} // Disable category field when adding images
              />
              <input
                type="file"
                name="image"
                onChange={handleImageChange}
                style={{
                  border: "2px solid green",
                  color: "black",
                  padding: "5px",
                }}
                className="text-base block w-full mb-4 rounded-md"
              />

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setAddingEvent(false)}
                  className="mr-4 text-red-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-buttonColor text-white px-5 py-2 rounded-lg hover:bg-green-600"
                  disabled={loading}
                >
                  {isEditing
                    ? "Update Event"
                    : isAddingImages
                    ? "Add Images"
                    : "Submit Event"}
                  {loading && (
                    <svg
                      className="animate-spin h-5 w-5 text-white mx-auto"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="hidden"> hidden </div>
      )}
    </div>
  );
};

export default ManageGallery;

// Individual template
const ManageGalleryTemp = ({
  galleryData,
  onEditEvent,
  onAddImages,
  onDeleteEvent,
  onDeleteImage,
}) => {
  return (
    <div className="gallery-page pr-4 sm:py-3 sm:pr-4">
      {/* Loop through each event fetched from the API */}
      {galleryData.map((event, eventIndex) => (
        <EventGallery
          key={eventIndex}
          event={event}
          onEditEvent={onEditEvent}
          onAddImages={onAddImages}
          onDeleteEvent={onDeleteEvent}
          onDeleteImage={onDeleteImage}
        />
      ))}
    </div>
  );
};

// Images display control for each template
const EventGallery = ({
  event,
  onEditEvent,
  onAddImages,
  onDeleteEvent,
  onDeleteImage,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const totalImages = event.images.length;

  const prevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const nextImage = () => {
    if (currentImageIndex < totalImages - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const prevIndex = currentImageIndex > 0 ? currentImageIndex - 1 : null;
  const nextIndex =
    currentImageIndex < totalImages - 1 ? currentImageIndex + 1 : null;

  return (
    <div className="gallery-event mb-5 sm:mb-10 p-3 sm:p-6 bg-aboutbg shadow-lg rounded-lg">
      {/* Event details */}
      <div className="flex justify-between items-center">
        <h2 className="text-base sm:text-2xl font-semibold sm:font-bold mb-2">
          {event.title}
        </h2>

        <div className=" flex space-x-1 ">
          {/* button to edit content */}
          <div className="bg-white p-1 rounded-md">
            <CiEdit className="w-6 h-6" onClick={() => onEditEvent(event)} />
          </div>

          {/* Button to add more images to a particular category */}
          <div className="bg-white p-1 rounded-md">
            <IoMdAddCircle
              className="w-6 h-6"
              onClick={() => onAddImages(event.category)}
            />
          </div>

          {/* Delete the whole Event from db */}
          <div className=" bg-red-500 p-1 rounded-md">
            <MdOutlineDeleteForever
              className="w-6 h-6 "
              onClick={() => onDeleteEvent(event._id)} // Delete the whole event
            />
          </div>
        </div>
      </div>

      <p className="text-sm text-gray-600 mb-1">
        Category:{" "}
        <span className=" sm:text-ld text-xs bg-blue-400 px-2 py-1 sm:px-3 sm:py-2 rounded-lg font-Poppins font-bold text-gray-300">
          {event.category}
        </span>
      </p>
      <p className="text-sm text-gray-600 mb-8 sm:mb-4">
        Event Date: {event.eventDate || "No date provided"}
      </p>

      <div className="event-images relative h-40 sm:h-96 flex items-center justify-center">
        {/* Previous image */}
        {prevIndex !== null && (
          <div className="absolute transform -translate-x-1/2 opacity-50 transition-all duration-500 ease-in-out">
            <img
              src={event.images[prevIndex].imageUrl}
              alt={`${event.title} image ${prevIndex + 1}`}
              className="gallery-image h-28 sm:h-64 w-32 sm:w-72 object-cover rounded-md sm:rounded-lg"
            />
          </div>
        )}

        {/* Current image */}
        <div className="relative z-10 transition-all duration-500 ease-in-out transform scale-100">
          <img
            src={event.images[currentImageIndex].imageUrl}
            alt={`${event.title} image ${currentImageIndex + 1}`}
            className="gallery-image h-48 sm:h-96 w-36 sm:w-96 object-cover rounded-md sm:rounded-lg"
          />
          {/* The delete icon which is going to be assigned with each image. */}
          <div className="absolute bottom-2 right-2 bg-red-300 rounded-md sm:rounded-xl p-1 sm:p-2">
            <FaTrashCan
              className="z-[11] "
              onClick={() => onDeleteImage(event._id, currentImageIndex)} // Delete this image
            />
          </div>
        </div>

        {/* Next image */}
        {nextIndex !== null && (
          <div className="absolute transform translate-x-1/2 opacity-50 transition-all duration-500 ease-in-out">
            <img
              src={event.images[nextIndex].imageUrl}
              alt={`${event.title} image ${nextIndex + 1}`}
              className="gallery-image h-28 sm:h-64 w-32 sm:w-72 object-cover rounded-md sm:rounded-lg"
            />
          </div>
        )}

        {/* Navigation buttons positioned on sides */}
        <button
          onClick={prevImage}
          disabled={currentImageIndex === 0}
          className="z-30 absolute left-1 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 pl-2 sm:px-4 rounded-full sm:rounded disabled:opacity-50 transition-all duration-300 ease-in-out hover:scale-110"
        >
          <GrFormPreviousLink className="mr-2" />
        </button>

        <button
          onClick={nextImage}
          disabled={currentImageIndex === totalImages - 1}
          className="z-30 absolute right-1 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 pr-2 sm:px-4 rounded-full sm:rounded disabled:opacity-50 transition-all duration-300 ease-in-out hover:scale-110"
        >
          <GrFormNextLink className="ml-2" />
        </button>
      </div>

      <p className="font-bold sm:text-lg text-sm text-center mt-9 text-gray-500 w-full">
        <span className="text-white bg-blue-400 px-5 mr-1 rounded-md py-1">
          {currentImageIndex + 1}
        </span>
        / {totalImages}
      </p>
    </div>
  );
};
