import React, { useState } from "react";
import { Link } from "react-router-dom";

const icons = {
  Blog: "/adminsidebarimages/blog-icon.png",
  Gallery: "/adminsidebarimages/gallery-icon.png",
  Events: "/adminsidebarimages/event-icon.png",
  Requests: "/adminsidebarimages/requests-icon.png",
  Dashboard: "/adminsidebarimages/dashboard-icon.png",
  Website: "/adminsidebarimages/website-icon.png",
  Menu: "/adminsidebarimages/menu-icon.png", // Icon for the menu button
};

const AdminSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
      {/* Toggle button for small screens */}
      <div className="sm:hidden fixed bottom-64 right-5 z-50">
        <button onClick={toggleSidebar}>
          <img
            src={icons.Menu}
            className="w-10 h-10 border-2 border-blue-400 rounded-xl"
            alt="Menu Icon"
          />
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed sm:block left-2 sm:left-2 top-2 h-96 p-4 transition-transform transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0 z-50 bg-buttonColor rounded-3xl`}
        onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside
      >
        <div className="flex flex-col justify-between h-full">
          {/* Top icons */}
          <div className="space-y-4">
            <Link to="/admin/admindashboard-content/manage-blogs">
              <img
                src={icons.Blog}
                className="w-12 h-12 border-4 border-transparent hover:border-blue-500 transition-all duration-300 rounded-xl active:border-blue-500"
                alt="Blog Icon"
              />
            </Link>
            <Link to="/admin/admindashboard-content/manage-gallery">
              <img
                src={icons.Gallery}
                className="w-12 h-12 border-4 border-transparent hover:border-blue-500 transition-all duration-300 rounded-xl active:border-blue-500"
                alt="Gallery Icon"
              />
            </Link>
            <Link to="/admin/admindashboard-content/manage-events">
              <img
                src={icons.Events}
                className="w-12 h-12 border-4 border-transparent hover:border-blue-500 transition-all duration-300 rounded-xl active:border-blue-500"
                alt="Events Icon"
              />
            </Link>
            <Link to="/admin/admindashboard-content/manage-requests">
              <img
                src={icons.Requests}
                className="w-12 h-12 border-4 border-transparent hover:border-blue-500 transition-all duration-300 rounded-xl focus:outline-none focus:ring focus:ring-violet-300"
                alt="Requests Icon"
              />
            </Link>
            <Link to="/admin/admindashboard">
              <img
                src={icons.Dashboard}
                className="w-12 h-12 border-4 border-transparent hover:border-blue-500 transition-all duration-300 rounded-xl active:border-blue-500"
                alt="Dashboard Icon"
              />
            </Link>
          </div>

          {/* Bottom icon */}
          <Link
            to="/home"
            className="mt-auto items-end rounded-xl w-12 h-12 border-4 border-transparent hover:border-blue-500 transition-all duration-300"
          >
            <img
              src={icons.Website}
              className="rounded-lg"
              alt="Website Icon"
            />
          </Link>
        </div>
      </div>

      {/* Overlay for small screens */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-75 sm:hidden z-40 "
          onClick={closeSidebar}
        ></div>
      )}
    </>
  );
};

export default AdminSidebar;
