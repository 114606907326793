import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Blurhash } from "react-blurhash";

const Login = () => {
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await login(email, password);
      navigate("/admin/adminDashboard");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // this use effect is to track and set the loaded tio true after loading
  useEffect(() => {
    const image = new Image();
    image.src =
      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2Fblurry-corevalues-and-goals.png?alt=media&token=b21d3651-a137-44df-87bb-54aa715314ba";
    image.onload = () => setImageLoaded(true);
  }, []);

  return (
    <div className="main-container h-svh flex justify-center items-center relative">
      {/* The perfect conditional redering for a smooth image loading */}
      {imageLoaded ? (
        <img
          className="w-full h-full object-cover"
          src="https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2Fblurry-corevalues-and-goals.png?alt=media&token=b21d3651-a137-44df-87bb-54aa715314ba"
          alt="background"
        ></img>
      ) : (
        <Blurhash
          hash="eC97Ifa}Rjxuoe~WWCWBxujs.7ofWBoLjZ%Lt6ofWBR*t7ofoeRjay"
          width={"100%"}
          height={"100%"}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      )}

      {/* Image */}

      {/* Form */}
      <div className="absolute inset-0 flex justify-center items-center animate-fadeInUp">
        <form onSubmit={handleSubmit}>
          <div className=" bg-lightWhite backdrop-blur-sm mx-5 bg-opacity-40 flex flex-col px-4 py-16 h-80 space-y-5 sm:space-y-12 rounded-3xl shadow-lg shadow-black drop-shadow-xl">
            <div className="space-y-3 sm:space-y-8 text-center">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="text-base sm:text-base text-left bg-black bg-opacity-20 mix-blend-multiply shadow-xl placeholder:text-white placeholder:text-start rounded-2xl px-3 py-1 w-8/12 transition-transform duration-300 hover:scale-105"
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="text-base sm:text-base text-left bg-black bg-opacity-20 shadow-xl mix-blend-multiply placeholder:text-white placeholder:text-start rounded-2xl px-3 py-1 w-8/12 transition-transform duration-300 hover:scale-105"
              />
            </div>
            {error && (
              <div className="bg-red-100 text-red-700 p-4 rounded-md shadow-md mx-auto w-fit">
                <p>{error}</p>
              </div>
            )}
            <button
              type="submit"
              className="bg-loginButtonColour w-fit mx-auto px-16 py-1 rounded-2xl font-semibold transition-transform duration-300 hover:scale-110"
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white mx-auto"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                "Login"
              )}
            </button>
            <p className="mx-auto">
              <a href="">Forgot Details?</a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
