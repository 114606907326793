import React from "react";
import axios from "axios";

const ManageRequests = () => {
  return (
    <div className="manage-events ml-7 mt-8">
      {/* Top section */}
      <div className="flex items-center justify-between">
        <div>
          <span className="bg-buttonColor text-sm px-5 py-2 h-10 rounded-lg hover:bg-green-600">
            {" "}
            MANAGE REQUESTS{" "}
          </span>
        </div>
        {/* Button Image */}
        <div>
          <span className="bg-yellow-500 text-sm px-5 py-2 h-10 rounded-lg hover:bg-yellow-600 ml-5">
            {" "}
            All MEMBERS{" "}
          </span>
        </div>
      </div>

      <div className=" mt-4">
        <ManageRequestsTemp></ManageRequestsTemp>
        {/* Comntent would go here  */}
      </div>
    </div>
  );
};

export default ManageRequests;

const ManageRequestsTemp = () => {
  return <div>Under Development ....</div>;
};
